export enum TelegramPlatforms {
  android = 'android',
  ios = 'ios',
  macos = 'macos',
  tdesktop = 'tdesktop',
  unigram = 'unigram',
  unknown = 'unknown',
  web = 'web',
  weba = 'weba',
}
