import { createFileRoute } from '@tanstack/react-router';

import { NewEventPage } from '../../../../pages/events/ui/NewEventPage';

export const Route = createFileRoute('/_authorized/events/$eventId/draft')({
  component: Page,
});

function Page() {
  const { eventId } = Route.useParams();

  return <NewEventPage draftId={eventId} />;
}
