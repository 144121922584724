import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { Link, useNavigate } from '@tanstack/react-router';
import sanitize from 'sanitize-html';

import { AvatarCircles, Button, Skeleton } from '@tg-web/components';
import {
  CheckmarkIcon,
  DescriptionIcon,
  LocationIcon,
  NotificationIcon,
  QuestionMarkIcon,
  UsersIcon,
  VideoIcon,
} from '@tg-web/icons';
import { useBackNavigation } from '@tg-web/utils';

import {
  AttendStatus,
  DeleteEventButton,
  EditEventButton,
  EventActionsMenu,
  EventNotFound,
  EventType,
  JoinEventButton,
  MaybeEventButton,
  NotInterestedEventButton,
  NotificationEventButton,
  RescheduleSlotEventButton,
  ShareEventButton,
  useFormatEventDateAndTime,
} from '../../../features/events';
import { getFeedSearch } from '../../../features/feed';
import { AddToGoogleButton } from '../../../features/google';
import {
  NotificationDuration,
  NotificationDurationLabels,
} from '../../../features/notifications';
import { RecurrentType, RecurrentTypeInfo } from '../../../features/recurrency';
import { SendMessageButton } from '../../../features/telegram';
import { UserAvatar, formatFullName } from '../../../features/user';
import {
  User,
  useGetStack,
  useGoogleCalendarAccountConfigured,
  useShowEvent,
  useUserProfileConfigured,
} from '../../../shared/api';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { LinkifyWrapper } from '../../../shared/ui/LinkifyWrapper';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { TruncateContainer } from '../../../shared/ui/TruncateContainer';
import { ViewField } from '../../../shared/ui/ViewField';
import { getSlotPartnerFromEvent } from '../../../widgets/slots/lib/getSlotPartnerFromEvent';

export interface ViewEventPageProps {
  eventId: string;
  fromFeed: boolean;
}

export function ViewEventPage({ eventId, fromFeed }: ViewEventPageProps) {
  const { t } = useTranslation();
  const { formatEventDateAndTime } = useFormatEventDateAndTime();
  const navigate = useNavigate();
  const { analytics } = useJitsu();

  const userProfile = useUserProfileConfigured();
  const { googleAccount, googleAccountsList } =
    useGoogleCalendarAccountConfigured({
      enabled:
        userProfile.isSuccess &&
        userProfile.data.user.data.attributes.has_google_calendar,
    });

  const event = useShowEvent({
    pathParams: { id: eventId },
  });
  const stackId = event.data?.event.data.attributes.stack_id;
  const slotId = event.data?.event.data.attributes.slot_id;
  const stackReq = useGetStack(
    {
      pathParams: { id: stackId ?? '' },
    },
    { enabled: !!stackId },
  );

  useBackNavigation(() => {
    if (stackId && !fromFeed) {
      navigate({ params: { stackId }, to: '/stacks/$stackId/view' });
    } else {
      navigate({
        search: getFeedSearch(event.data?.event.data),
        to: '/feed',
      });
    }
  });

  if (
    event.isPending ||
    userProfile.isPending ||
    googleAccountsList.isLoading ||
    googleAccount.isLoading
  ) {
    return <GlobalLoading />;
  }

  if (event.isError) {
    if (event.error?.status === 404) {
      return <EventNotFound />;
    }
    throw new Error('Unexpected API error');
  }

  if (
    userProfile.isError ||
    googleAccountsList.isError ||
    googleAccount.isError
  ) {
    throw new Error('Unexpected API error');
  }

  const eventData = event.data.event.data;
  const attributes = eventData.attributes;
  const relationships = eventData.relationships;
  const isGoogleEvent = eventData.attributes.event_type === EventType.GOOGLE;

  const fields = [
    {
      icon: <LocationIcon className="text-tg-hint" />,
      key: 'location',
      value: attributes.location ? (
        <LinkifyWrapper>{attributes.location}</LinkifyWrapper>
      ) : null,
    },
    {
      icon: <NotificationIcon className="text-tg-hint" />,
      key: 'notification',
      value:
        attributes.notify_before === NotificationDuration.noNotify
          ? undefined
          : t(
              NotificationDurationLabels[
                attributes.notify_before as NotificationDuration
              ],
            ),
    },
    {
      icon: <VideoIcon className="text-tg-hint" />,
      key: 'videoLink',
      value: attributes.video_link && (
        <TruncateContainer bgColor="section-bg">
          <a
            className="text-tg-link"
            href={attributes.video_link}
            rel="noreferrer"
            target="_blank"
          >
            {attributes.video_link}
          </a>
        </TruncateContainer>
      ),
    },
    {
      icon: <DescriptionIcon className="text-tg-hint" />,
      key: 'description',
      value:
        attributes.description && !slotId ? (
          isGoogleEvent ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(attributes.description, {
                  transformTags: {
                    a: (tagName, attribs) => ({
                      attribs: {
                        ...attribs,
                        target: '_blank',
                      },
                      tagName,
                    }),
                  },
                }),
              }}
              className="google-description"
            />
          ) : (
            <LinkifyWrapper>{attributes.description}</LinkifyWrapper>
          )
        ) : null,
    },
    {
      icon: <UsersIcon className="text-tg-hint" />,
      key: 'participants',
      value: attributes.attendees_count > 1 && !slotId && (
        <Link
          className="-mt-1 flex flex-row items-center gap-2"
          params={{ eventId }}
          search={{ fromFeed }}
          to="/events/$eventId/participants"
        >
          <span className="text-tg-accent-text">
            {t('all:view_event.participants', {
              count: attributes.attendees_count,
            })}
          </span>
          <AvatarCircles
            avatarUrls={attributes.attendees_photo_urls.filter(
              (it) => it !== null,
            )}
            numOfAvatars={3}
            numPeople={attributes.attendees_count}
          />
        </Link>
      ),
    },
  ] as const;

  const eventCreator = event.data.event.included
    .filter((it) => it.type === 'user')
    .find((it) => it.id === relationships.user.data.id)! as User;

  const isCreator = eventCreator.id === userProfile.data.user.data.id;
  const isStackEvent = Boolean(eventData.attributes.stack_id);

  const attendStatus = attributes.attend_status;
  const isAttendee = [AttendStatus.attend, AttendStatus.maybe].includes(
    attendStatus,
  );
  const isNotAttendee = [
    AttendStatus.missed,
    AttendStatus.notAttended,
  ].includes(attendStatus);

  const googleCalendar = googleAccount.data?.google_calendars.data.find(
    (it) => it.id === eventData.attributes.google_calendar_id,
  );

  const slotPartner = getSlotPartnerFromEvent(
    event.data,
    userProfile.data.user.data,
  );

  return (
    <PageWrapper className="flex flex-col justify-between gap-4 px-5 py-6">
      <div className="flex flex-col">
        {!isCreator && !stackId && !slotId && (
          <div className="mb-5 flex flex-row gap-2">
            <UserAvatar
              className="h-[22px] w-[22px]"
              fallbackClassName="typo-small"
              user={eventCreator}
            />
            <span className="typo-text">{formatFullName(eventCreator)}</span>
          </div>
        )}
        {stackId && stackReq.isPending && (
          <Skeleton className="bg-tg-section-bg mb-5 h-8 w-full" />
        )}
        {stackId && stackReq.isSuccess && (
          <div className="mb-5 flex flex-row items-center gap-3">
            <span className="text-[32px] leading-none">
              {stackReq.data.emoji}
            </span>
            <span className="typo-text overflow-hidden text-ellipsis whitespace-nowrap">
              {stackReq.data.title}
            </span>
          </div>
        )}
        {slotId && slotPartner ? (
          <div className="flex items-center gap-2">
            <UserAvatar className="h-6 w-6" user={slotPartner} />
            <h1 className="typo-header-big">{formatFullName(slotPartner)}</h1>
          </div>
        ) : (
          <h1 className="typo-header-big">{attributes.title}</h1>
        )}

        <p className="typo-text mb-6 mt-2 whitespace-pre-wrap first-letter:uppercase">
          {formatEventDateAndTime(eventData)}
          {attributes.recurrent_type !== RecurrentType.noRecurrency && (
            <>
              ,<br />
              {t(RecurrentTypeInfo[attributes.recurrent_type as RecurrentType])}
            </>
          )}
        </p>
        {!isCreator && attendStatus === AttendStatus.attend && (
          <div className="bg-tg-section-bg mb-3 flex flex-row items-center gap-2.5 self-start rounded-[14px] px-3 py-2">
            <CheckmarkIcon className="h-6 w-6" />
            <span className="typo-text">{t('all:view_event.you_are_in')}</span>
          </div>
        )}
        {!isCreator && attendStatus === AttendStatus.maybe && (
          <div className="bg-tg-section-bg mb-3 flex flex-row items-center gap-2.5 self-start rounded-[14px] px-3 py-2">
            <QuestionMarkIcon className="h-6 w-6" />
            <span className="typo-text">{t('all:common.buttons.maybe')}</span>
          </div>
        )}
        <div className="flex flex-col">
          {fields
            .filter(({ value }) => Boolean(value))
            .map(({ icon, key, value }) => (
              <ViewField
                innerClassName="min-w-0 w-full break-words"
                key={key}
                leftIcon={icon}
              >
                {value}
              </ViewField>
            ))}
        </div>
        {isGoogleEvent && googleCalendar && (
          <div className="mt-3">
            <ViewField
              leftIcon={
                <div className="flex h-8 w-8 items-center justify-center">
                  <div
                    className="h-4 w-4 rounded-full"
                    style={{ backgroundColor: googleCalendar.attributes.color }}
                  />
                </div>
              }
              innerClassName="min-w-0 w-full break-words"
            >
              {googleCalendar.attributes.name}
            </ViewField>
          </div>
        )}
        {!isGoogleEvent &&
          !userProfile.data.user.data.attributes.has_google_calendar &&
          (isCreator || isAttendee) && (
            <AddToGoogleButton className="mt-3" event={eventData} />
          )}
        {slotId && (
          <SendMessageButton
            onClick={() => {
              analytics.track(SlotsAnalytics.SEND_MESSAGE, { eventId, slotId });
            }}
            className="mt-3"
            user={slotPartner}
          />
        )}
      </div>
      {isGoogleEvent ? (
        <div className="flex flex-col items-center text-center">
          <img
            alt="Google calendar icon"
            className="h-12 w-12"
            src="/images/gcal_icon.webp"
          />
          <p className="typo-text text-tg-hint mt-3">
            {t('all:view_event.google_event.hint')}
          </p>
          {eventData.attributes.google_link && (
            <Button
              className="bg-tg-section-bg text-tg-accent-text typo-text mt-5"
              asChild
            >
              <a
                href={eventData.attributes.google_link}
                rel="noreferrer"
                target="_blank"
              >
                {t('all:view_event.google_event.view_button')}
              </a>
            </Button>
          )}
        </div>
      ) : (
        <div className="flex w-full flex-row items-center justify-evenly gap-3">
          {isCreator && !isStackEvent && !slotId && (
            <>
              <DeleteEventButton event={eventData} fromFeed={fromFeed} />
              <EditEventButton event={eventData} fromFeed={fromFeed} />
              <ShareEventButton event={eventData} />
            </>
          )}
          {isCreator && isStackEvent && (
            <>
              <EventActionsMenu
                event={eventData}
                fromFeed={fromFeed}
                onNotificationChange={() => event.refetch()}
              />
              <ShareEventButton event={eventData} />
              {isNotAttendee && (
                <>
                  <MaybeEventButton
                    onSuccess={() => {
                      navigate({
                        params: { eventId: eventData.id },
                        to: '/events/$eventId/attend',
                      });
                    }}
                    event={eventData}
                    text={t('all:view_event.stack_event_owner.maybe')}
                  />
                  <JoinEventButton
                    onSuccess={() => {
                      navigate({
                        params: { eventId: eventData.id },
                        to: '/events/$eventId/attend',
                      });
                    }}
                    event={eventData}
                    text={t('all:view_event.stack_event_owner.going')}
                  />
                </>
              )}
              {isAttendee && (
                <>
                  <NotInterestedEventButton
                    onSuccess={() => {
                      navigate({ to: '/feed' });
                    }}
                    event={eventData}
                    text={t('all:view_event.stack_event_owner.not_going')}
                  />
                  {attendStatus === AttendStatus.attend && (
                    <MaybeEventButton
                      onSuccess={() => {
                        event.refetch();
                      }}
                      event={eventData}
                    />
                  )}
                  {attendStatus === AttendStatus.maybe && (
                    <JoinEventButton
                      onSuccess={() => {
                        event.refetch();
                      }}
                      event={eventData}
                    />
                  )}
                </>
              )}
            </>
          )}
          {slotId && (
            <>
              {isCreator && <RescheduleSlotEventButton event={eventData} />}
              <DeleteEventButton
                event={eventData}
                fromFeed={fromFeed}
                text={t('all:common.buttons.cancel')}
              />
              <NotificationEventButton
                onSuccess={() => {
                  event.refetch();
                }}
                event={eventData}
              />
            </>
          )}
          {!isCreator && isNotAttendee && (
            <>
              <NotInterestedEventButton
                onSuccess={() => {
                  navigate({ to: '/feed' });
                }}
                event={eventData}
                skipChangeAttention={attendStatus === AttendStatus.missed}
              />
              <MaybeEventButton
                onSuccess={() => {
                  navigate({
                    params: { eventId: eventData.id },
                    to: '/events/$eventId/attend',
                  });
                }}
                event={eventData}
              />
              <JoinEventButton
                onSuccess={() => {
                  navigate({
                    params: { eventId: eventData.id },
                    to: '/events/$eventId/attend',
                  });
                }}
                event={eventData}
              />
            </>
          )}
          {!isCreator && isAttendee && !slotId && (
            <>
              {attendStatus === AttendStatus.attend && (
                <MaybeEventButton
                  onSuccess={() => {
                    event.refetch();
                  }}
                  event={eventData}
                />
              )}
              {attendStatus === AttendStatus.maybe && (
                <JoinEventButton
                  onSuccess={() => {
                    event.refetch();
                  }}
                  event={eventData}
                />
              )}
              <NotInterestedEventButton
                onSuccess={() => {
                  navigate({ to: '/feed' });
                }}
                event={eventData}
                isAttend
              />
              <NotificationEventButton
                onSuccess={() => {
                  event.refetch();
                }}
                event={eventData}
              />
              <ShareEventButton event={eventData} />
            </>
          )}
        </div>
      )}
    </PageWrapper>
  );
}
