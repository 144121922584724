import { addMinutes, interval } from 'date-fns';

import { Event, EventDraft, StackEvent } from '../../../shared/api';
import { isDraftEvent } from './isDraftEvent';
import { isStackEvent } from './isStackEvent';

export function getEventInterval(event: Event | EventDraft | StackEvent) {
  const startAt =
    isDraftEvent(event) || isStackEvent(event)
      ? event.start_at
      : event.attributes.start_at;
  const duration =
    isDraftEvent(event) || isStackEvent(event)
      ? event.duration
      : event.attributes.duration;
  return interval(startAt, addMinutes(startAt, duration));
}
