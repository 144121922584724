import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { CheckmarkIcon } from '@tg-web/icons';

import { SlotEventInfo } from '../../../features/slots/ui/SlotEventInfo';
import { useNotifyEvent } from '../../../shared/api';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { NotificationSelect } from '../../../widgets/events/ui/NotificationSelect';

export interface AttendSlotEventPageProps {
  eventId: string;
}

export function AttendSlotEventPage({ eventId }: AttendSlotEventPageProps) {
  const { t } = useTranslation();
  const notifyEvent = useNotifyEvent();
  const { analytics } = useJitsu();

  return (
    <PageWrapper className="flex flex-col gap-10 px-5 pb-3 pt-10">
      <div className="flex flex-col items-center">
        <div className="bg-tg-button flex h-16 w-16 items-center justify-center rounded-full">
          <CheckmarkIcon
            className="text-tg-button-text"
            height={32}
            width={32}
          />
        </div>
        <h1 className="typo-header-biggest mt-5">
          {t('all:slots.attend.title')}
        </h1>
        <p className="typo-text text-tg-subtitle-text mt-3">
          {t('all:slots.attend.description')}
        </p>
      </div>
      <SlotEventInfo eventId={eventId}>
        {(event) => (
          <div className="mt-3">
            <NotificationSelect
              onOpenChange={(open) => {
                analytics.track(
                  open
                    ? SlotsAnalytics.NOTIFICATION_SELECT_OPEN
                    : SlotsAnalytics.NOTIFICATION_SELECT_CLOSE,
                  { eventId: event.id, slotId: event.attributes.slot_id },
                );
              }}
              onValueChange={(value) => {
                notifyEvent.mutate({
                  body: { dependent_notify: 0, notify_before: parseInt(value) },
                  pathParams: { id: event.id },
                });
                analytics.track(SlotsAnalytics.NOTIFICATION_CHANGE, {
                  eventId: event.id,
                  notifyBefore: value,
                  slotId: event.attributes.slot_id,
                });
              }}
              defaultValue={event.attributes.notify_before.toString()}
            />
          </div>
        )}
      </SlotEventInfo>
      <Button className="mt-auto" variant="primary" asChild>
        <Link
          onClick={() =>
            analytics.track(SlotsAnalytics.ATTEND_PAGE_CONTINUE, { eventId })
          }
          params={{ eventId }}
          search={{ fromFeed: false }}
          to="/events/$eventId/view"
        >
          {t('all:common.buttons.continue')}
        </Link>
      </Button>
    </PageWrapper>
  );
}
