import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { useNavigate, useRouter } from '@tanstack/react-router';

import { useToast } from '@tg-web/components';
import { useBackNavigation } from '@tg-web/utils';

import { SlotsForm, SlotsFormProps } from '../../../features/slots';
import { useShowOwnSlot, useUpdateSlot } from '../../../shared/api';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';

export function SlotsSettingsPage() {
  const [backNavigationDisabled, setBackNavigationDisabled] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  useBackNavigation(router.history.back, !backNavigationDisabled);
  const slotReq = useShowOwnSlot({});
  const updateSlotReq = useUpdateSlot();
  const toast = useToast();
  const { analytics } = useJitsu();

  const handleSubmit: SlotsFormProps['onSubmit'] = (values) => {
    updateSlotReq.mutate({
      body: values,
    });
    analytics.track(SlotsAnalytics.SETTINGS_SUBMIT);
  };

  useEffect(() => {
    if (updateSlotReq.isSuccess) {
      navigate({ to: '/slots' });
      toast.toast({
        description: t('all:slots.main.saved'),
      });
    }
  }, [updateSlotReq.isSuccess]);

  if (slotReq.isError) {
    throw new Error('Unexpected API error');
  }

  if (slotReq.isPending) {
    return <GlobalLoading />;
  }

  return (
    <PageWrapper className="flex flex-col gap-6 px-5 py-3">
      <h1 className="typo-header-big">{t('all:slots.settings.title')}</h1>
      <SlotsForm
        defaultValues={slotReq.data.slot}
        isLoading={updateSlotReq.isPending}
        onModalStateChange={setBackNavigationDisabled}
        onSubmit={handleSubmit}
        submitButtonText={t('all:common.buttons.save')}
      />
    </PageWrapper>
  );
}
