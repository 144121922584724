import { Trans, useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';
import { CheckmarkIcon, FingerIcon, TimelineIcon } from '@tg-web/icons';

import { SlotsAnalytics } from '../../../shared/model/analytics';

export function EmptySlots() {
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  return (
    <div className="mt-8 flex flex-col items-center gap-2">
      <div className="flex flex-col items-center gap-4 pb-4 pt-2">
        <span className="text-[56px] leading-none">🕒</span>
        <span className="typo-header-big px-3 text-center">
          {t('all:slots.empty.title')}
        </span>
      </div>
      <div className="flex flex-col gap-1 px-3">
        <div className="flex py-2">
          <TimelineIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3 whitespace-pre-wrap">
            <Trans
              components={{
                bold: <span className="typo-text-bold" />,
              }}
              i18nKey="all:slots.empty.pointOne"
            />
          </span>
        </div>
        <div className="flex py-2">
          <FingerIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3 whitespace-pre-wrap">
            <Trans
              components={{
                bold: <span className="typo-text-bold" />,
              }}
              i18nKey="all:slots.empty.pointTwo"
            />
          </span>
        </div>
        <div className="flex py-2">
          <CheckmarkIcon className="text-tg-hint shrink-0" />
          <span className="typo-text ml-3 whitespace-pre-wrap">
            <Trans
              components={{
                bold: <span className="typo-text-bold" />,
              }}
              i18nKey="all:slots.empty.pointThree"
            />
          </span>
        </div>
      </div>
      <Button
        onClick={() =>
          analytics.track(SlotsAnalytics.START_PAGE_CONTINUE_CLICK)
        }
        className="mt-4"
        variant="primary"
        asChild
      >
        <Link to="/slots/onboarding">{t('all:slots.empty.button')}</Link>
      </Button>
    </div>
  );
}
