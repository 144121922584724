import { getWebApp } from '@tg-web/utils';

import { TelegramPlatforms } from '../../../shared/model/tgPlatforms';

export function isSharingSupported() {
  return (
    Number(getWebApp().version) >= 8 &&
    [
      TelegramPlatforms.android,
      TelegramPlatforms.ios,
      TelegramPlatforms.macos,
      TelegramPlatforms.tdesktop,
    ].includes(getWebApp().platform as TelegramPlatforms)
  );
}
