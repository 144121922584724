import { useTranslation } from 'react-i18next';

import { ShareIcon, SpinnerIcon } from '@tg-web/icons';
import { cn } from '@tg-web/utils';

import { Event } from '../../../shared/api';
import { useShareEvent } from '../lib';
import { EventActionButton } from './EventActionButton';

export interface ShareEventButtonProps {
  event: Event;
  isPrimary?: boolean;
  text?: string;
}

export function ShareEventButton({
  event,
  isPrimary = false,
  text,
}: ShareEventButtonProps) {
  const { isSharePending, shareEvent } = useShareEvent();
  const { t } = useTranslation();

  const handleClick = () => shareEvent(event);

  return (
    <EventActionButton
      icon={
        isSharePending ? (
          <SpinnerIcon className="h-8 w-8 animate-spin" />
        ) : (
          <ShareIcon />
        )
      }
      iconClassName={cn(isPrimary && 'bg-tg-button text-tg-button-text')}
      onClick={handleClick}
      text={text ?? t('all:common.buttons.share')}
    />
  );
}
