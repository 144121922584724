import { useTranslation } from 'react-i18next';

import { Link } from '@tanstack/react-router';

import { Stack } from '../../../shared/api';
import { ShareStackButton, ShareStackModalProvider } from './ShareStack';

export interface StackCardProps {
  stack: Stack;
}

export function StackCard({ stack }: StackCardProps) {
  const { t } = useTranslation();

  return (
    <ShareStackModalProvider stackId={stack.id}>
      <Link params={{ stackId: stack.id }} to="/stacks/$stackId/view">
        <div className="bg-tg-section-bg flex flex-col gap-4 rounded-2xl px-4 py-3">
          <div className="flex gap-2">
            <span className="text-[20px] leading-none">{stack.emoji}</span>
            <span className="typo-text-bold">{stack.title}</span>
          </div>
          <div className="flex items-center justify-start gap-2">
            <span className="typo-feed text-tg-hint">
              {t('all:stacks.card.subscribers', {
                count: stack.subscriptions_count,
              })}
            </span>
            <ShareStackButton
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              className="ml-auto"
              size="tiny"
              type="button"
              variant="secondary"
            />
          </div>
        </div>
      </Link>
    </ShareStackModalProvider>
  );
}
