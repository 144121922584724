import { SelectProps } from '@radix-ui/react-select';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tg-web/components';
import { NotificationIcon, ShevronDownIcon } from '@tg-web/icons';
import { useDisclosure } from '@tg-web/utils';

import { useNotificationOptions } from '../../../features/notifications';
import { ViewField } from '../../../shared/ui/ViewField';

export function NotificationSelect(props: SelectProps) {
  const { onOpen, onToggle, open } = useDisclosure();
  const { notificationOptions } = useNotificationOptions();

  const handleOpenChange = (open: boolean) => {
    onToggle();
    props.onOpenChange?.(open);
  };

  return (
    <ViewField
      rightIcon={
        <ShevronDownIcon className={open ? 'text-tg-button' : 'text-tg-hint'} />
      }
      className="cursor-pointer"
      innerClassName="min-w-0 -mr-5 w-full"
      leftIcon={<NotificationIcon className="text-tg-hint" />}
      onClick={onOpen}
    >
      <Select open={open} {...props} onOpenChange={handleOpenChange}>
        <SelectTrigger className="typo-text w-full justify-between">
          <SelectValue />
        </SelectTrigger>
        <SelectContent
          onClick={(event) => {
            event.stopPropagation();
          }}
          alignOffset={54}
          sideOffset={15}
        >
          {notificationOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </ViewField>
  );
}
