import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { Link } from '@tanstack/react-router';

import { Button } from '@tg-web/components';

import { SlotsAnalytics } from '../../../shared/model/analytics';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { SlotsGooglePageHeader } from './SlotsGooglePageHeader';

export interface SlotsGoogleWelcomeScreenProps {
  isLoading: boolean;
  onContinueButtonClick: VoidFunction;
}

export function SlotsGoogleWelcomeScreen({
  isLoading,
  onContinueButtonClick,
}: SlotsGoogleWelcomeScreenProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const handleContinueClick = () => {
    onContinueButtonClick();
    analytics.track(SlotsAnalytics.CONNECT_GOOGLE_CONTINUE);
  };
  const handleSkipClick = () => {
    analytics.track(SlotsAnalytics.CONNECT_GOOGLE_SKIP);
  };

  return (
    <PageWrapper className="flex flex-col justify-between px-5 py-3">
      <SlotsGooglePageHeader />
      <div className="flex flex-col items-center gap-4">
        <div className="flex gap-4">
          <img
            alt="Google calendar icon"
            className="h-14 w-14"
            src="/images/gcal_icon.webp"
          />
          <img
            alt="Google Meet icon"
            className="h-14 w-14"
            src="/images/gmeet_icon.webp"
          />
        </div>
        <p className="typo-text max-w-[270px] text-center">
          {t('all:slots.google.init.text')}
        </p>
      </div>
      <div>
        <Button
          isLoading={isLoading}
          onClick={handleContinueClick}
          variant="primary"
        >
          {t('common.buttons.continue')}
        </Button>
        <Button
          className="mt-3"
          onClick={handleSkipClick}
          variant="ghost"
          asChild
        >
          <Link to="/slots" replace>
            {t('all:slots.google.init.skip')}
          </Link>
        </Button>
      </div>
    </PageWrapper>
  );
}
