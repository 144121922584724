import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { DialogProps } from '@radix-ui/react-dialog';

import {
  Button,
  ButtonProps,
  Sheet,
  SheetContent,
  useToast,
} from '@tg-web/components';
import { PinIcon, ShareSecondaryIcon, SpinnerIcon } from '@tg-web/icons';
import { getWebApp, noop, useDisclosure } from '@tg-web/utils';

import {
  Stack,
  useCreateStackShareLink,
  useShareStackViaBot,
} from '../../../shared/api';
import { StacksAnalytics } from '../../../shared/model/analytics';
import { isSharingSupported } from '../../events';
import { useCopyStackLink } from '../lib';
import shareImg from './images/share.webp';

const ShareStackContext = createContext({
  isPending: false,
  onClick: noop,
});

export interface ShareStackModalProviderProps extends PropsWithChildren {
  onOpenChange?: DialogProps['onOpenChange'];
  stackId: Stack['id'];
}

export function ShareStackModalProvider({
  children,
  onOpenChange,
  stackId,
}: ShareStackModalProviderProps) {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const toast = useToast();
  const { onClose, onOpen, onToggle, open } = useDisclosure();
  const { copyStackLink } = useCopyStackLink(stackId);
  const shareStackReq = useShareStackViaBot();
  const createStackShareLinkRequest = useCreateStackShareLink();

  const handleShareButtonClick = () => {
    analytics.track(StacksAnalytics.SHARE_VIA_BOT, { stackId });

    shareStackReq.mutate({ pathParams: { stackId } });
  };
  const handleChange: DialogProps['onOpenChange'] = (value) => {
    onOpenChange?.(value);
    onToggle();
  };

  useEffect(() => {
    if (shareStackReq.isSuccess) {
      if (onOpenChange) {
        onOpenChange(false);
      }
      onClose();
      toast.toast({
        description: t('all:stacks.share.success'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareStackReq.isSuccess]);

  const handleShareClick = useCallback(async () => {
    if (isSharingSupported()) {
      analytics.track(StacksAnalytics.SHARE_LINK, { stackId });

      const shareLinkResult = await createStackShareLinkRequest.mutateAsync({
        body: {
          token_id: import.meta.env.VITE_BOT_TOKEN_ID ?? '0',
        },
        pathParams: {
          id: stackId,
        },
      });

      // @ts-expect-error new api. Remove ignore after @types/telegram-web-app update to v8
      getWebApp().shareMessage(shareLinkResult.prepared_inline_message.id);
    } else {
      onOpen();
      onOpenChange?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackId]);

  const contextValue = useMemo(() => {
    return {
      isPending: createStackShareLinkRequest.isPending,
      onClick: handleShareClick,
    };
  }, [createStackShareLinkRequest.isPending, handleShareClick]);

  return (
    <ShareStackContext.Provider value={contextValue}>
      {children}
      <Sheet onOpenChange={handleChange} open={open}>
        <SheetContent
          className="bg-tg-section-bg flex flex-col gap-0 px-5 py-10"
          side="bottom"
        >
          <span className="typo-header-big text-center">
            {t('all:stacks.share.title')}
          </span>
          <img
            alt={t('all:stacks.share.title')}
            className="mt-9"
            src={shareImg}
          />
          <div className="flex gap-3 py-2">
            <PinIcon className="shrink-0" />
            <span className="typo-text">
              {t('all:stacks.share.description')}
            </span>
          </div>
          <div className="flex flex-col gap-3 pt-4">
            <Button
              disabled={shareStackReq.isPending}
              onClick={handleShareButtonClick}
              variant="primary"
            >
              {t('all:stacks.share.send_me')}
            </Button>
            <Button onClick={copyStackLink} variant="secondary">
              {t('all:stacks.share.copy_link.button')}
            </Button>
          </div>
        </SheetContent>
      </Sheet>
    </ShareStackContext.Provider>
  );
}

export type ShareStackButtonProps = Omit<ButtonProps, 'children'>;
export function ShareStackButton(props: ShareStackButtonProps) {
  const { t } = useTranslation();
  const { isPending, onClick } = useContext(ShareStackContext);

  return (
    <Button
      {...props}
      onClick={(event) => {
        props.onClick?.(event);
        onClick();
      }}
    >
      {isPending ? (
        <SpinnerIcon className="h-6 w-6 animate-spin p-1" />
      ) : (
        <ShareSecondaryIcon />
      )}
      {t('all:common.buttons.share')}
    </Button>
  );
}
