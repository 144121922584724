import { ShowEventResponse, User } from '../../../shared/api';

export function getSlotPartnerFromEvent(
  data: ShowEventResponse,
  currentUser: User,
): User {
  const partner = data.event.included.find(
    (item) => item.type === 'user' && item.id !== currentUser.id,
  ) as User | undefined;
  // return currentUser when the slot was booked by themselves
  return partner ?? currentUser;
}
