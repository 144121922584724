import * as React from 'react';
import { ReactNode } from 'react';

import {
  Event,
  useShowEvent,
  useUserProfileConfigured,
} from '../../../shared/api';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { ViewField } from '../../../shared/ui/ViewField';
import { getSlotPartnerFromEvent } from '../../../widgets/slots/lib/getSlotPartnerFromEvent';
import { getEventInterval } from '../../events';
import { UserAvatar, formatFullName } from '../../user';

export interface SlotEventInfoProps {
  children?: (event: Event) => ReactNode;
  eventId: string;
}

export function SlotEventInfo({ children, eventId }: SlotEventInfoProps) {
  const { format } = useLocalisedDateFormat();
  const userProfile = useUserProfileConfigured();
  const eventReq = useShowEvent({
    pathParams: { id: eventId },
  });

  if (eventReq.isError || userProfile.isError) {
    throw new Error('Unexpected API error');
  }
  if (eventReq.isPending || userProfile.isPending) {
    return <GlobalLoading />;
  }

  const { end, start } = getEventInterval(eventReq.data.event.data);
  const slotOwner = getSlotPartnerFromEvent(
    eventReq.data,
    userProfile.data.user.data,
  );

  return (
    <div>
      <div>
        <ViewField className="px-4">
          <div className="flex items-center gap-3">
            <UserAvatar className="h-6 w-6" user={slotOwner} />
            <h2 className="typo-header-small">{formatFullName(slotOwner)}</h2>
          </div>
        </ViewField>
        <ViewField className="px-4">
          <span className="typo-text capitalize">
            {format(start, 'EEEE, dd MMM · HH:mm')}
            {' - '}
            {format(end, 'HH:mm')}
          </span>
        </ViewField>
      </div>
      {children?.(eventReq.data.event.data)}
    </div>
  );
}
