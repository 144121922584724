import * as React from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';
import { type VariantProps, cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { cn } from '@tg-web/utils';

const Tabs = TabsPrimitive.Root;

const tabsListVariants = cva('flex', {
  defaultVariants: {
    size: 'medium',
    variant: 'ghost',
  },
  variants: {
    size: {
      big: 'h-14 rounded-2xl p-1',
      medium: 'h-10 rounded-xl p-0.5',
    },
    variant: {
      ghost: 'h-11 gap-8 px-5 pt-2',
      primary: 'bg-tg-secondary-bg text-tg-text relative w-full gap-1',
    },
  },
});

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabsListVariants>
>(({ className, size, variant, ...props }, ref) => (
  <TabsPrimitive.List
    className={tabsListVariants({ className, size, variant })}
    ref={ref}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const tabsTriggerVariants = cva(
  'inline-flex data-[state=active]:text-tg-text',
  {
    defaultVariants: {
      size: 'medium',
      variant: 'ghost',
    },
    variants: {
      size: {
        big: 'py-3.5',
        medium: 'py-2',
      },
      variant: {
        ghost:
          'typo-text data-[state=active]:border-tg-text text-tg-hint whitespace-nowrap border-b-2 border-transparent transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        primary:
          'typo-text-bold relative h-full basis-1/2 justify-center border-none data-[state=active]:border-none data-[state=active]:bg-transparent data-[state=active]:shadow-none',
      },
    },
  },
);

export interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    VariantProps<typeof tabsTriggerVariants> {}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ children, className, size, value, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={cn(tabsTriggerVariants({ className, size, variant }))}
    ref={ref}
    value={value}
    {...props}
  >
    {variant === 'primary' ? (
      <span className="absolute">{children}</span>
    ) : (
      children
    )}
  </TabsPrimitive.Trigger>
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    className={cn(
      'ring-offset-background focus-visible:ring-ring grow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
      className,
    )}
    ref={ref}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export interface TabsAnimatedBackdropProps
  extends VariantProps<typeof tabsTriggerVariants> {
  /* Amount of tabs triggers */
  count: number;
  currentTab: number;
}
const TabsAnimatedBackdrop = ({
  count,
  currentTab,
  size,
}: TabsAnimatedBackdropProps) => {
  const baseOffset = size === 'big' ? 8 : 4;
  return (
    <motion.span
      animate={{
        x: `calc(${currentTab * 100}% - ${currentTab === 0 ? 0 : baseOffset}px)`,
      }}
      className={cn('bg-tg-section-bg absolute inset-0.5 rounded-[10px]', {
        'inset-1': size === 'big',
        'rounded-[14px]': size === 'big',
        'w-1/2': count === 2,
        'w-1/3': count === 3,
        'w-1/4': count === 4,
      })}
      transition={{ bounce: 0.2, duration: 0.6, type: 'spring' }}
    />
  );
};

export { Tabs, TabsAnimatedBackdrop, TabsContent, TabsList, TabsTrigger };
