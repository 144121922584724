import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@tg-web/components';
import { PropsWithCN, cn, useBoolean } from '@tg-web/utils';

import { useUserProfileConfigured } from '../../../shared/api';
import { getCloudStorageItem } from '../../../shared/lib/getCloudStorageItem';
import { setCloudStorageItem } from '../../../shared/lib/setCloudStorageItem';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';

type GoogleCalendarSettingsTooltipProps = PropsWithChildren &
  PropsWithCN & { disable?: boolean };

export function GoogleCalendarSettingsTooltip({
  children,
  className,
  disable = false,
}: GoogleCalendarSettingsTooltipProps) {
  const { i18n, t } = useTranslation();
  const [showTooltip, setShowTooltip] = useBoolean();

  const userProfile = useUserProfileConfigured();
  const hasGoogleCalendar =
    userProfile.data?.user.data.attributes.has_google_calendar;

  const hideTooltip = useCallback(() => {
    setShowTooltip.off();
    setCloudStorageItem(CloudStorageKeys.HIDE_GOOGLE_SETTINGS_TOOLTIP, 'true');
  }, [setShowTooltip]);

  useEffect(() => {
    if (userProfile.isPending) {
      return;
    }

    getCloudStorageItem(CloudStorageKeys.HIDE_GOOGLE_SETTINGS_TOOLTIP).then(
      (value) => {
        if (!value) {
          if (hasGoogleCalendar) {
            hideTooltip();
          } else {
            setShowTooltip.on();
          }
        }
      },
    );
  }, [hideTooltip, setShowTooltip, hasGoogleCalendar, userProfile.isPending]);

  if (disable) {
    return children;
  }

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        className={cn(
          i18n.language === 'en' ? 'max-w-[275px]' : 'max-w-[330px]',
          className,
        )}
        collisionPadding={{ left: 5 }}
        onPointerDownOutside={hideTooltip}
        sideOffset={-10}
      >
        <TooltipArrow />
        {t('all:feed.google.tooltip.text')}
      </TooltipContent>
    </Tooltip>
  );
}
