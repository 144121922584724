import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { useNavigate, useRouter } from '@tanstack/react-router';

import { useBackNavigation } from '@tg-web/utils';

import { SlotsForm, SlotsFormProps } from '../../../features/slots';
import {
  useCreateSlot,
  useShowOwnSlot,
  useUpdateSlot,
} from '../../../shared/api';
import { setCloudStorageItem } from '../../../shared/lib/setCloudStorageItem';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import { PageWrapper } from '../../../shared/ui/PageWrapper';
import { Stepper } from '../../../shared/ui/Stepper';

export function SlotsOnboardingPage() {
  const { analytics } = useJitsu();
  const [backNavigationDisabled, setBackNavigationDisabled] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  useBackNavigation(router.history.back, !backNavigationDisabled);
  const createSlotReq = useCreateSlot();
  const updateSlotReq = useUpdateSlot();
  // Requests the slot in case the user navigates back from the next screen
  const slotReq = useShowOwnSlot(
    {},
    {
      retry: false,
    },
  );

  const handleSubmit: SlotsFormProps['onSubmit'] = (values) => {
    if (slotReq.data?.slot) {
      updateSlotReq.mutate({
        body: values,
      });
    } else {
      createSlotReq.mutate({
        body: values,
      });
    }
    analytics.track(SlotsAnalytics.ONBOARDING_SUBMIT);
  };

  useEffect(() => {
    if (createSlotReq.isSuccess || updateSlotReq.isSuccess) {
      setCloudStorageItem(CloudStorageKeys.SHOW_SLOTS_READY_BADGE, 'true');
      navigate({ to: '/slots/google-init' });
    }
  }, [createSlotReq.isSuccess, updateSlotReq.isSuccess]);

  if (slotReq.isPending) {
    return <GlobalLoading />;
  }

  return (
    <PageWrapper className="px-5 py-3">
      <h1 className="typo-header-big">{t('all:slots.onboarding.title')}</h1>
      <p className="typo-text mt-2">{t('all:slots.onboarding.description')}</p>
      <Stepper activeIndex={0} className="mb-7 mt-5" count={2} />
      <SlotsForm
        defaultValues={slotReq.data?.slot}
        isLoading={createSlotReq.isPending}
        onModalStateChange={setBackNavigationDisabled}
        onSubmit={handleSubmit}
        submitButtonText={t('all:slots.onboarding.submit_button')}
      />
    </PageWrapper>
  );
}
