export enum CommonAnalytics {
  APP_OPEN = 'appOpen',
}

export enum EventsAnalytics {
  ADD_TO_GOOGLE = 'eventActionAddToGoogle',
  CHANGE_NOTIFICATION = 'eventActionChangeNotification',
  COPY_LINK = 'eventActionCopyLink',
  DELETE = 'eventActionDelete',
  EDIT = 'eventActionEdit',
  JOIN = 'eventActionJoin',
  MAYBE = 'eventActionMaybe',
  NOT_INTERESTED = 'eventActionNotInterested',
  SHARE = 'eventActionShare',
}

export enum StacksAnalytics {
  COPY_LINK = 'stackActionCopyLink',
  SHARE_LINK = 'stackActionShareLink',
  SHARE_VIA_BOT = 'stackActionShareLinkViaBot',
}

export enum SlotsAnalytics {
  ATTEND_PAGE_CONTINUE = 'slotsActionAttendPageContinue',
  CONNECT_GOOGLE_CONTINUE = 'slotsActionConnectGoogleContinue',
  CONNECT_GOOGLE_SKIP = 'slotsActionConnectGoogleSkip',
  DAY_CLICK = 'slotsActionDayClick',
  EVENTS_TAB = 'slotsActionEventsTab',
  GOOGLE_MEET_CONTINUE = 'slotsActionGoogleMeetContinue',
  LINK_DISABLE = 'slotsActionLinkDisable',
  LINK_ENABLE = 'slotsActionLinkEnable',
  NOTIFICATION_CHANGE = 'slotsActionNotificationChange',
  NOTIFICATION_SELECT_CLOSE = 'slotsActionNotificationSelectClose',
  NOTIFICATION_SELECT_OPEN = 'slotsActionNotificationSelectOpen',
  ONBOARDING_SUBMIT = 'slotsActionOnboardingSubmit',
  RESCHEDULE_BUTTON = 'slotsActionRescheduleButton',
  RESCHEDULE_SUBMIT = 'slotsActionRescheduleSubmit',
  SEND_MESSAGE = 'slotsActionSendMessage',
  SETTINGS_SUBMIT = 'slotsActionSettingsSubmit',
  SHARE_CLICK = 'slotsActionShareClick',
  SLOTS_MAIN_TABS_CLICK = 'slotsActionSlotsMainTabsClick',
  SLOTS_TAB = 'slotsActionSlotsTab',
  START_PAGE_CONTINUE_CLICK = 'slotsActionStartPageContinueClick',
  TIME_CLICK = 'slotsActionTimeClick',
  TIME_SUBMIT = 'slotsActionTimeSubmit',
}
