import { WeekDays } from './scheduleSchemas';

export const weekDaysArray: Array<WeekDays> = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
