import { TZDate } from '@date-fns/tz';

export function formatTimeZone(timezone: string) {
  const city = timezone.split('/')[1];
  const timezoneDate = TZDate.tz(timezone).toISOString();
  const offset = timezoneDate.slice(-6, -3);
  return {
    city: city ? city.split('_').join(' ') : '',
    offset: `(GMT${offset.indexOf('0') === 1 ? `${offset[0]}${offset[2]}` : offset})`,
  };
}
