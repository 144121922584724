import { PropsWithChildren, useEffect } from 'react';

import { useJitsu } from '@jitsu/jitsu-react';

import { getWebApp } from '@tg-web/utils';

import { AuthContext } from '../../../shared/model/authContext';
import { CloudStorageKeys } from '../../../shared/model/cloudStorageKeys';
import { useUserLoginWithStatus } from '../lib';

export function AuthProvider({ children }: PropsWithChildren) {
  const { analytics } = useJitsu();

  const authLogin = useUserLoginWithStatus({
    onSuccess: (data) => {
      analytics.identify(data.data.user.data.id, {
        colorScheme: getWebApp().colorScheme,
        environment: import.meta.env.VITE_APP_ENV,
        platform: getWebApp().platform,
        version: getWebApp().version,
      });
    },
  });

  useEffect(() => {
    authLogin.mutate({
      body: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        token_id: Number(import.meta.env.VITE_BOT_TOKEN_ID ?? '0'),
        user_data: getWebApp().initData,
      },
    });
  }, []);

  useEffect(() => {
    if (authLogin.isSuccess && authLogin.data.status === 201) {
      getWebApp().CloudStorage.setItem(CloudStorageKeys.SHOW_WELCOME, 'true');
    }
  }, [authLogin.data?.status, authLogin.isSuccess]);

  if (authLogin.isPending || authLogin.isIdle) {
    return null;
  }

  if (authLogin.isError) {
    return 'Access denied';
  }

  return (
    <AuthContext.Provider
      value={{
        token: authLogin.data.data.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
