import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@tg-web/components';
import { cn, getWebApp } from '@tg-web/utils';

import { User } from '../../../shared/api';

export interface SendMessageButtonProps extends ButtonProps {
  user: User;
}

/**
 * Open chat with user within Telegram app
 */
export function SendMessageButton({
  className,
  onClick,
  user,
  ...rest
}: SendMessageButtonProps) {
  const { t } = useTranslation();

  const handleClick: ButtonProps['onClick'] = (event) => {
    getWebApp().openTelegramLink(`https://t.me/${user.attributes.username}`);
    onClick?.(event);
  };

  return user.attributes.username ? (
    <Button
      className={cn(className, 'typo-text text-tg-link')}
      onClick={handleClick}
      variant="white"
      {...rest}
    >
      {t('all:common.ui.send_message_button')}
    </Button>
  ) : null;
}
