import { useTranslation } from 'react-i18next';

import { Time } from '@internationalized/date';

import { Checkbox, TimeInput } from '@tg-web/components';
import { PlusCircleIcon, RemoveIcon } from '@tg-web/icons';

import { getWeekDays } from '../../../shared/lib/getWeekDays';
import { useAppLang } from '../../../shared/lib/useAppLang';
import { useUserLocale } from '../../../shared/lib/useUserLocale';
import { ViewField } from '../../../shared/ui/ViewField';
import { WeekDays, WeekSchedule } from '../model';
import { defaultSlot } from '../model/slotFormValue';

export interface ScheduleFieldProps {
  onBlur: VoidFunction;
  onChange: (value: WeekSchedule) => void;
  value: WeekSchedule;
}

const unavailableDayLabels: Record<'en' | 'ru', Record<WeekDays, string>> = {
  en: {
    friday: 'Fridays',
    monday: 'Mondays',
    saturday: 'Saturdays',
    sunday: 'Sundays',
    thursday: 'Thursdays',
    tuesday: 'Tuesdays',
    wednesday: 'Wednesdays',
  },
  ru: {
    friday: 'пятницам',
    monday: 'понедельникам',
    saturday: 'субботам',
    sunday: 'воскресеньям',
    thursday: 'четвергам',
    tuesday: 'вторникам',
    wednesday: 'средам',
  },
};

export function ScheduleField({ onBlur, onChange, value }: ScheduleFieldProps) {
  const { t } = useTranslation();
  const { appLang } = useAppLang();
  const { userLocale } = useUserLocale();

  const getDaySchedule = (day: WeekDays) => {
    return value[day] ?? [];
  };
  const handleAddClick = (day: WeekDays) => {
    onChange({ ...value, [day]: [...getDaySchedule(day), defaultSlot] });
  };
  const handleRemoveClick = (day: WeekDays, index: number) => {
    const daySchedule = [...getDaySchedule(day)];
    daySchedule.splice(index, 1);
    onChange({ ...value, [day]: daySchedule });
  };
  const handleStartTimeChange = (
    day: WeekDays,
    index: number,
    newValue: Time,
  ) => {
    const daySchedule = [...getDaySchedule(day)];
    daySchedule[index].startTime = newValue;
    onChange({ ...value, [day]: daySchedule });
  };
  const handleEndTimeChange = (
    day: WeekDays,
    index: number,
    newValue: Time,
  ) => {
    const daySchedule = [...getDaySchedule(day)];
    daySchedule[index].endTime = newValue;
    onChange({ ...value, [day]: daySchedule });
  };
  const handleEnableDay = (day: WeekDays) => {
    onChange({ ...value, [day]: [defaultSlot] });
  };
  const handleDisableDay = (day: WeekDays) => {
    onChange({ ...value, [day]: undefined });
  };

  return (
    <div>
      {getWeekDays(userLocale).map(({ key, label }) => {
        const daySchedule = value[key as WeekDays];
        if (daySchedule) {
          return (
            <ViewField
              className="py-1.5"
              innerClassName="flex min-h-12 w-full items-start"
              key={key}
            >
              <div className="flex items-center pt-3">
                <Checkbox
                  checked={true}
                  id={key}
                  onClick={() => handleDisableDay(key as WeekDays)}
                />
                <label className="typo-text ml-3 capitalize" htmlFor={key}>
                  {label}
                </label>
              </div>
              <div className="ml-auto flex flex-col gap-2">
                {daySchedule.map(({ endTime, startTime }, index) => (
                  <div className="flex items-center gap-2" key={index}>
                    <TimeInput
                      onChange={(value) =>
                        handleStartTimeChange(key as WeekDays, index, value)
                      }
                      className="bg-tg-secondary-bg ml-auto h-12"
                      onBlur={onBlur}
                      value={startTime}
                    />
                    —
                    <TimeInput
                      onChange={(value) =>
                        handleEndTimeChange(key as WeekDays, index, value)
                      }
                      className="bg-tg-secondary-bg ml-auto h-12"
                      onBlur={onBlur}
                      value={endTime}
                    />
                    {index === 0 ? (
                      <PlusCircleIcon
                        className="ml-2"
                        height={32}
                        onClick={() => handleAddClick(key as WeekDays)}
                        width={32}
                      />
                    ) : (
                      <RemoveIcon
                        onClick={() =>
                          handleRemoveClick(key as WeekDays, index)
                        }
                        className="ml-2"
                      />
                    )}
                  </div>
                ))}
              </div>
            </ViewField>
          );
        }
        return (
          <ViewField
            className="py-1.5"
            innerClassName="flex items-center h-12 w-full"
            key={key}
          >
            <Checkbox
              checked={false}
              id={key}
              onClick={() => handleEnableDay(key as WeekDays)}
            />
            <label className="typo-text ml-3 capitalize" htmlFor={key}>
              {label}
            </label>
            <div className="typo-text text-tg-hint ml-auto min-w-[236px]">
              {t('all:slots.form.schedule.unavailable_on')}{' '}
              {unavailableDayLabels[appLang][key as keyof WeekSchedule]}
            </div>
          </ViewField>
        );
      })}
    </div>
  );
}
