import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';

import { useToast } from '@tg-web/components';

export function useSlotBookingError(
  slotId: string,
  isError: boolean,
  status: number | undefined,
) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isError && status === 422) {
      toast.toast({
        description: t('all:slots.errors.unavailable'),
      });
      queryClient.invalidateQueries({
        queryKey: ['slots', slotId],
      });
    }
  }, [isError, status, slotId]);
}
