import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { useNavigate } from '@tanstack/react-router';

import { RescheduleIcon } from '@tg-web/icons';

import { Event } from '../../../shared/api';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { EventActionButton } from './EventActionButton';

export interface RescheduleSlotEventButtonProps {
  event: Event;
}

export function RescheduleSlotEventButton({
  event,
}: RescheduleSlotEventButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { analytics } = useJitsu();

  const handleClick = () => {
    analytics.track(SlotsAnalytics.RESCHEDULE_BUTTON, {
      eventId: event.id,
      slotId: event.attributes.slot_id,
    });
    navigate({
      params: { eventId: event.id, slotId: event.attributes.slot_id! },
      to: '/slots/$eventId/$slotId/reschedule',
    });
  };

  return (
    <EventActionButton
      icon={<RescheduleIcon />}
      onClick={handleClick}
      text={t('all:common.buttons.reschedule')}
    />
  );
}
