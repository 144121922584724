import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';

import { getWebApp } from '@tg-web/utils';

import { OwnSlot, useCreateSlotShareLink } from '../../../shared/api';
import { openShareDialog } from '../../../shared/lib/openShareDialog';
import { SlotsAnalytics } from '../../../shared/model/analytics';
import { StartParams } from '../../../shared/model/startParamsStore';
import { isSharingSupported } from '../../events';

export function useShareSlots() {
  const { t } = useTranslation();
  const { analytics } = useJitsu();
  const createSlotShareLinkRequest = useCreateSlotShareLink();

  const shareSlots = async (slot: OwnSlot) => {
    analytics.track(SlotsAnalytics.SHARE_CLICK);

    if (isSharingSupported()) {
      const shareLinkResult = await createSlotShareLinkRequest.mutateAsync({
        body: {
          token_id: import.meta.env.VITE_BOT_TOKEN_ID ?? '0',
        },
        pathParams: {
          id: slot.id,
        },
      });

      // @ts-expect-error new api. Remove ignore after @types/telegram-web-app update to v8
      getWebApp().shareMessage(shareLinkResult.prepared_inline_message.id);
    } else {
      const startParams: StartParams = {
        id: slot.id,
        mode: 'viewSlots',
      };
      const link = `https://t.me/${import.meta.env.VITE_TG_BOT}/${import.meta.env.VITE_TG_MINI_APP}?startapp=${btoa(
        JSON.stringify(startParams),
      )}`;

      openShareDialog([' ', t('all:slots.main.share_text')].join('\n'), link);
    }
  };

  return {
    isShareSlotsPending: createSlotShareLinkRequest.isPending,
    shareSlots,
  };
}
