import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Time } from '@internationalized/date';
import { useNavigate } from '@tanstack/react-router';
import {
  format,
  getHours,
  getMinutes,
  interval,
  isWithinInterval,
  startOfDay,
} from 'date-fns';

import { OverlapEvents, getEventInterval } from '../../../features/events';
import { useFeedOptimisticUpdater } from '../../../features/feed/lib/useFeedOptimisticUpdater';
import {
  CreateEventRequestBody,
  useCreateEvent,
  useShowEventDraft,
} from '../../../shared/api';
import { DATE_SEARCH_PARAM_FORMAT } from '../../../shared/model/search';
import { GlobalLoading } from '../../../shared/ui/GlobalLoading';
import {
  EditEventForm,
  EditEventFormProps,
} from '../../../widgets/events/ui/EditEventForm';
import { MY_CALENDAR_FAKE_STACK_ID } from '../../../widgets/events/ui/StackSelect';

export type NewEventPageProps = {
  draftId?: string;
  initialDate?: Date;
  stackId?: string;
};
export function NewEventPage({
  draftId,
  initialDate,
  stackId,
}: NewEventPageProps) {
  const { t } = useTranslation();
  const updateFeedData = useFeedOptimisticUpdater();

  const [formToSave, setFormToSave] = useState<
    CreateEventRequestBody | undefined
  >(undefined);
  const navigate = useNavigate();

  const eventDraft = useShowEventDraft(
    {
      pathParams: { id: draftId ?? '' },
    },
    { enabled: Boolean(draftId), refetchOnWindowFocus: false },
  );

  const creteEvent = useCreateEvent({
    onSuccess: (data) => {
      updateFeedData((feedData) => {
        const updatedPages = feedData.pages.map((page, index) => {
          const queryParams = feedData.pageParams[index].queryParams;
          if (!queryParams?.from || !queryParams?.to) {
            return page;
          }

          if (
            isWithinInterval(
              data.event.data.attributes.start_at ?? new Date(),
              interval(queryParams.from, queryParams.to),
            )
          ) {
            return { events: { data: [...page.events.data, data.event.data] } };
          }

          return page;
        });

        return {
          pageParams: feedData.pageParams,
          pages: updatedPages,
        };
      });
    },
  });

  useEffect(() => {
    if (creteEvent.isSuccess) {
      navigate({
        params: { eventId: creteEvent.data.event.data.id },
        search: {
          stackId: creteEvent.data.event.data.attributes.stack_id ?? undefined,
        },
        to: '/events/$eventId/created',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creteEvent.isSuccess]);

  const handleOverlapCancel = () => setFormToSave(undefined);

  const handleOverlapContinue = () => {
    setFormToSave(undefined);
    creteEvent.mutate({ body: formToSave! });
  };

  const handleSubmit: EditEventFormProps['onSubmit'] = (form) => {
    if (form.stack_id) {
      creteEvent.mutate({ body: form });
    } else {
      setFormToSave(form);
    }
  };

  const handleGoBack = () => {
    if (stackId) {
      navigate({ params: { stackId }, to: '/stacks/$stackId/view' });
    } else {
      navigate({
        search: initialDate
          ? { date: format(initialDate, DATE_SEARCH_PARAM_FORMAT) }
          : {},
        to: '/feed',
      });
    }
  };

  const hasFormToSave = Boolean(formToSave);

  const initialForm = useMemo<EditEventFormProps['initialForm']>(() => {
    const draftEvent = eventDraft.data?.event_draft;
    if (!draftEvent) {
      return undefined;
    }

    const eventInterval = getEventInterval(draftEvent);

    return {
      description: draftEvent.description ?? '',
      endsAtDate: startOfDay(eventInterval.end),
      endsAtTime: new Time(
        getHours(eventInterval.end),
        getMinutes(eventInterval.end),
      ),
      location: draftEvent.location ?? '',
      notifyBefore: draftEvent.notify_before,
      recurrent: draftEvent.recurrent_type,
      stackId: MY_CALENDAR_FAKE_STACK_ID,
      startsAtDate: startOfDay(eventInterval.start),
      startsAtTime: new Time(
        getHours(eventInterval.start),
        getMinutes(eventInterval.start),
      ),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title: draftEvent.title,
      videoLink: draftEvent.video_link ?? '',
    };
  }, [eventDraft.data]);

  // Show loader only on first fetch
  if (draftId && eventDraft.isPending) {
    return <GlobalLoading />;
  }

  if (eventDraft.isError) {
    throw new Error('Unexpected API error');
  }

  return (
    <>
      <EditEventForm
        hideSubmitButton={hasFormToSave}
        initialDate={initialDate}
        initialForm={initialForm}
        isPending={creteEvent.isPending}
        onBack={handleGoBack}
        onSubmit={handleSubmit}
        stackId={stackId}
        submitButtonText={t('all:event.buttons.create')}
      />
      <OverlapEvents
        duration={formToSave ? formToSave.duration.toString() : ''}
        enabled={hasFormToSave}
        onCancel={handleOverlapCancel}
        onContinue={handleOverlapContinue}
        start_at={formToSave ? formToSave.start_at : ''}
      />
    </>
  );
}
