import { useTranslation } from 'react-i18next';

import { useJitsu } from '@jitsu/jitsu-react';
import { formatDuration, intervalToDuration } from 'date-fns';

import { getWebApp } from '@tg-web/utils';

import {
  Event,
  StackEvent,
  useCreateEventShareLink,
} from '../../../shared/api';
import { openShareDialog } from '../../../shared/lib/openShareDialog';
import { useLocalisedDateFormat } from '../../../shared/lib/useLocalisedDateFormat';
import { EventsAnalytics } from '../../../shared/model/analytics';
import { createViewEventDeepLink } from './createViewEventDeepLink';
import { getEventInterval } from './getEventInterval';
import { isSharingSupported } from './isSharingSupported';
import { isStackEvent } from './isStackEvent';

export function useShareEvent() {
  const { format, userLocale } = useLocalisedDateFormat();
  const { t } = useTranslation();
  const { analytics } = useJitsu();

  const createEventShareLinkRequest = useCreateEventShareLink();

  const shareEvent = async (event: Event | StackEvent) => {
    analytics.track(EventsAnalytics.SHARE, { eventId: event.id });

    if (isSharingSupported()) {
      const shareLinkResult = await createEventShareLinkRequest.mutateAsync({
        body: {
          token_id: import.meta.env.VITE_BOT_TOKEN_ID ?? '0',
        },
        pathParams: {
          id: event.id,
        },
      });

      // @ts-expect-error new api. Remove ignore after @types/telegram-web-app update to v8
      getWebApp().shareMessage(shareLinkResult.prepared_inline_message.id);
    } else {
      const eventInterval = getEventInterval(event);
      const viewEventDeepLink = createViewEventDeepLink(
        isStackEvent(event) ? event.token : event.attributes.token,
      );
      const title = isStackEvent(event) ? event.title : event.attributes.title;

      openShareDialog(
        [
          t('all:event.share.title'),
          t('all:event.share.text'),
          '',
          `💥 ${title}`,
          `📅 ${t('all:event.share.when', {
            date: format(eventInterval.start, 'd MMMM yyy'),
            time: format(eventInterval.start, 'HH:mm'),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}`,
          `🕒 ${formatDuration(intervalToDuration(eventInterval), { locale: userLocale })}`,
        ].join('\n'),
        viewEventDeepLink,
      );
    }
  };

  return {
    isSharePending: createEventShareLinkRequest.isPending,
    shareEvent,
  };
}
